@import './burger.css';

:root {
  --primary-color: #ac9755;
  --primary-color-lighter: #beaa65;
  --primary-color-shade: #f9f8f3;
  --primary-color-shade-darker: #cfc49c;
  --secondary-color: #f8f8f8;
  --text-color: #101010;
  --link-color: #2b89db;
}

html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  font-family: 'Poppins', sans-serif;
  margin: 0;
  color: var(--text-color);
}

h1,
h2,
h3 {
  margin: 0;
}

h1 {
  font-size: 3.3rem;
}

h2 {
  font-size: 2.3rem;
}

h3 {
  font-size: 1.5rem;
}

@media only screen and (max-width: 780px) {
  h2 {
    font-size: 2rem;
  }
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

p {
  margin: 0;
}

.container {
  max-width: 1320px;
  margin: auto;
  padding: 0 15px;
}

.icon {
  fill: var(--primary-color);
  height: 24px;
  width: 24px;
}

.link {
  color: var(--link-color);
  text-decoration: none;
}

.link--text {
  color: var(--text-color);
}

.grid {
  display: grid;
  justify-items: center;
}

.grid--1x4 {
  gap: 40px 20px;
  grid-template-columns: repeat(4, 1fr);
}

/* Top bar */

.top-bar {
  background-color: var(--primary-color-shade);
  padding: 0.3rem 0;
}

.top-bar__content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.top-bar__location {
  display: flex;
  align-items: center;
}

.top-bar__phone {
  font-weight: 500;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
}

.top-bar__icon {
  margin-right: 0.3rem;
}

.top-bar__phone .top-bar__icon {
  width: 22px;
  height: 22px;
}

.top-bar__phone .top-bar__text {
  top: -0.1rem;
  font-weight: 500;
  font-size: 1.2rem;
}

@media only screen and (max-width: 580px) {
  .top-bar {
    background-color: var(--primary-color-shade);
  }

  .top-bar__content {
    flex-direction: column;
    justify-content: center;
  }

  .top-bar__phone .top-bar__text,
  .top-bar__text {
    font-size: 1rem;
  }
}

/* Navbar */

.navbar {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.068);
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 1;
}

.navbar__content {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
}

.navbar__brand {
  display: flex;
  align-items: center;
}

.navbar__brand-link {
  display: flex;
  align-items: center;
}

.navbar__logo {
  width: 190px;
}

.navbar__nav {
  display: flex;
  align-items: center;
}

.navbar__item {
  margin-left: 2rem;
}

.navbar__link {
  display: inline-block;
  text-decoration: none;
  padding: 0.5rem 1rem;
  border-radius: 2px;
}

.navbar__link:link,
.navbar__link:visited {
  color: var(--text-color);
  transition: color 0.3s;
}
.navbar__link:hover,
.navbar__link:active {
  color: var(--primary-color);
}

.navbar__link.active {
  background-color: var(--primary-color);
  color: #fff;
  box-shadow: 0 3px 6px #ac985559;
}

.navbar__last-link {
  padding-right: 0;
}

.navbar__last-link.active {
  padding-right: 1rem;
}

@media only screen and (max-width: 715px) {
  /* Responsive Navbar */
  .navbar__nav {
    transition: all 0.3s;
    height: 0;
    overflow: hidden;
    width: 100%;
    flex-direction: column;
  }

  .navbar__nav.show {
    display: flex;
    height: 120px;
    margin-top: 1rem;
  }

  .navbar__item {
    width: 100%;
    margin-left: 0;
    text-align: center;
  }

  .navbar__link {
    width: 100%;
  }

  .navbar__last-link {
    padding-right: 1rem;
  }
}

@media only screen and (max-width: 580px) {
  .navbar__logo {
    width: 150px;
  }
}

/* Hero */

.hero {
  background-image: url('../images/home.jpg');
  background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url('../images/home.jpg');
  background-size: cover;
  background-attachment: local;
  height: 600px;
  z-index: -1;
}

.hero__content {
  height: 600px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.hero__header,
.hero__keyword {
  color: #fff;
  text-shadow: 0 3px 6px rgba(0, 0, 0, 0.123);
}

.hero__keyword {
  display: block;
  font-size: 4rem;
  font-weight: 600;
}

@media only screen and (max-width: 600px) {
  h1 {
    font-size: 2.5rem;
  }

  .hero__keyword {
    font-size: 3rem;
  }

  .hero,
  .hero__content {
    height: 400px;
  }
}

@media only screen and (max-width: 450px) {
  h1 {
    font-size: 2rem;
  }

  .hero__keyword {
    font-size: 2.5rem;
  }

  .hero,
  .hero__content {
    height: 300px;
  }
}

/* Sections */

section {
  padding: 3rem 0;
}

@media only screen and (max-width: 780px) {
  section {
    padding: 2rem 0;
  }
}

/* Our Services */

.services {
  background-color: var(--secondary-color);
}

.services__header {
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 3rem;
}

.services__clippath-container {
  position: absolute;
  width: 0;
  height: 0;
  overflow: hidden;
}

.services__image {
  width: 150px;
  height: 150px;
  object-fit: cover;
  clip-path: url(#services__clippath);
  display: block;
  margin: 0 auto;
}

.services__title {
  font-size: 1.2rem;
  text-align: center;
  margin-top: 0.7rem;
}

.services__description {
  text-align: center;
  font-size: 1rem;
  padding: 0 20px;
}

@media only screen and (max-width: 1100px) {
  .services__image {
    width: 100px;
    height: 100px;
  }
}

@media only screen and (max-width: 880px) {
  .grid--1x4 {
    grid-template-columns: repeat(2, 1fr);
  }

  .services__image {
    width: 150px;
    height: 150px;
  }

  .services__description {
    text-align: center;
    font-size: 1rem;
    max-width: 250px;
  }
}

@media only screen and (max-width: 570px) {
  .grid--1x4 {
    gap: 30px 10px;
    grid-template-columns: repeat(2, 1fr);
  }

  .services__image {
    width: 130px;
    height: 130px;
  }

  .services__header {
    margin-bottom: 2rem;
  }
}

@media only screen and (max-width: 450px) {
  .grid--1x4 {
    gap: 30px 10px;
    grid-template-columns: repeat(1, 1fr);
  }

  .services__description {
    max-width: 100%;
  }

  .services__description {
    padding: 0 20px;
  }
}

/* Bio Section */

.bio {
  background-image: linear-gradient(var(--primary-color-shade-darker), #fff);
}

.bio-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.bio-photo img {
  width: 200px;
}

.bio-desc {
  max-width: 500px;
  text-align: center;
  margin-top: 20px;
}

@media only screen and (max-width: 543px) {
  .bio-photo img {
    width: 150px;
    margin-right: 0;
    margin-bottom: 10px;
  }

  .bio-desc {
    max-width: 90%;
    margin-top: 0;
  }
}

/* Get in touch */

.contact__header {
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 0.5rem;
}

.contact__sub-header {
  text-align: center;
  font-weight: 500;
  margin-bottom: 3rem;
}

.form {
  max-width: 600px;
  margin: 0 auto;
}

.form__control {
  border-radius: 7px;
  border: 1px solid var(--primary-color);
  box-shadow: var(--box-shadow);
  background-color: var(--primary-color-shade);
  display: block;
  font-family: 'Poppins', sans-serif;
  font-size: 1.2rem;
  margin-bottom: 1.3rem;
  outline: none;
  padding: 1.2rem 1rem 1.2rem 1rem;
  transition: box-shadow 0.3s;
  width: 100%;
}

.form__control::placeholder {
  color: var(--primary-color);
}

.form__control:focus {
  box-shadow: 1px 3px 6px #ac985559;
}

textarea.form__control {
  resize: none;
}

.form__submit-btn {
  background-color: var(--primary-color);
  border-radius: 2px;
  border: none;
  box-shadow: 0 3px 6px #ac985559;
  color: #fff;
  cursor: pointer;
  font-family: 'Poppins', sans-serif;
  font-size: 1.1rem;
  outline: none;
  padding: 1rem 1.3rem;
  transition: background-color 0.3s;
}

.form__submit-btn:hover {
  background-color: var(--primary-color-lighter);
}

.form__submit-btn:focus {
  background-color: var(--primary-color-lighter);
}

/* Footer */

.footer {
  background-color: var(--primary-color-shade);
}

.footer__content {
  display: flex;
}

.footer section {
  flex: 1 1 0px;
}

.footer__header {
  text-transform: uppercase;
  margin-bottom: 1rem;
}

/* Footer - Our Goal */

.goal {
  margin-right: 2rem;
}

.goal__text {
  font-size: 1.3rem;
}

/* Footer - Contact Info */

.contact-info__group {
  display: flex;
  align-items: center;
  font-size: 1.3rem;
  margin-bottom: 1rem;
  font-weight: 500;
}

.contact-info__phone,
.contact-info__email {
  margin-left: 1rem;
}

.contact-info__icon {
  width: 32px;
  height: 32px;
}

.footer__copyright {
  padding-bottom: 3rem;
}

@media only screen and (max-width: 860px) {
  .footer__content {
    flex-direction: column;
  }

  .goal {
    padding-bottom: 1rem;
  }

  .contact-info {
    padding-top: 0;
    padding-bottom: 0.6rem;
  }

  .goal__text,
  .contact-info__group {
    font-size: 1rem;
  }

  .footer__header {
    margin-bottom: 0.7rem;
  }

  .footer__copyright {
    padding-bottom: 2rem;
  }
}

/* Hidden link */

.link__hidden {
  display: none;
}
