.menu-btn {
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  justify-content: center;
  align-items: center;
  width: 26px;
  height: 30px;
  transition: all .3s ease-in-out;
  display: none;
}

.menu-btn__burger, .menu-btn__burger:before, .menu-btn__burger:after {
  background-color: var(--primary-color);
  border-radius: 5px;
  width: 24px;
  height: 3px;
  transition: all .3s ease-in-out;
}

.menu-btn__burger:before, .menu-btn__burger:after {
  content: "";
  position: absolute;
}

.menu-btn__burger:before {
  transform: translateY(-7px);
}

.menu-btn__burger:after {
  transform: translateY(7px);
}

.menu-btn.open .menu-btn__burger {
  background: none;
  transform: translateY(50px);
}

.menu-btn.open .menu-btn__burger:before {
  transform: translateY(-50px);
}

.menu-btn.open .menu-btn__burger:after {
  transform: translateY(-43px);
}

@media screen and (width <= 715px) {
  .menu-btn {
    display: flex;
  }
}

:root {
  --primary-color: #ac9755;
  --primary-color-lighter: #beaa65;
  --primary-color-shade: #f9f8f3;
  --primary-color-shade-darker: #cfc49c;
  --secondary-color: #f8f8f8;
  --text-color: #101010;
  --link-color: #2b89db;
}

html {
  box-sizing: border-box;
}

*, :before, :after {
  box-sizing: inherit;
}

body {
  color: var(--text-color);
  margin: 0;
  font-family: Poppins, sans-serif;
}

h1, h2, h3 {
  margin: 0;
}

h1 {
  font-size: 3.3rem;
}

h2 {
  font-size: 2.3rem;
}

h3 {
  font-size: 1.5rem;
}

@media only screen and (width <= 780px) {
  h2 {
    font-size: 2rem;
  }
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

p {
  margin: 0;
}

.container {
  max-width: 1320px;
  margin: auto;
  padding: 0 15px;
}

.icon {
  fill: var(--primary-color);
  width: 24px;
  height: 24px;
}

.link {
  color: var(--link-color);
  text-decoration: none;
}

.link--text {
  color: var(--text-color);
}

.grid {
  justify-items: center;
  display: grid;
}

.grid--1x4 {
  grid-template-columns: repeat(4, 1fr);
  gap: 40px 20px;
}

.top-bar {
  background-color: var(--primary-color-shade);
  padding: .3rem 0;
}

.top-bar__content {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.top-bar__location {
  align-items: center;
  display: flex;
}

.top-bar__phone {
  align-items: center;
  font-size: 1.2rem;
  font-weight: 500;
  display: flex;
}

.top-bar__icon {
  margin-right: .3rem;
}

.top-bar__phone .top-bar__icon {
  width: 22px;
  height: 22px;
}

.top-bar__phone .top-bar__text {
  font-size: 1.2rem;
  font-weight: 500;
  top: -.1rem;
}

@media only screen and (width <= 580px) {
  .top-bar {
    background-color: var(--primary-color-shade);
  }

  .top-bar__content {
    flex-direction: column;
    justify-content: center;
  }

  .top-bar__phone .top-bar__text, .top-bar__text {
    font-size: 1rem;
  }
}

.navbar {
  z-index: 1;
  background-color: #fff;
  position: sticky;
  top: 0;
  box-shadow: 0 3px 6px #0001;
}

.navbar__content {
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding-top: .3rem;
  padding-bottom: .3rem;
  display: flex;
}

.navbar__brand, .navbar__brand-link {
  align-items: center;
  display: flex;
}

.navbar__logo {
  width: 190px;
}

.navbar__nav {
  align-items: center;
  display: flex;
}

.navbar__item {
  margin-left: 2rem;
}

.navbar__link {
  border-radius: 2px;
  padding: .5rem 1rem;
  text-decoration: none;
  display: inline-block;
}

.navbar__link:link, .navbar__link:visited {
  color: var(--text-color);
  transition: color .3s;
}

.navbar__link:hover, .navbar__link:active {
  color: var(--primary-color);
}

.navbar__link.active {
  background-color: var(--primary-color);
  color: #fff;
  box-shadow: 0 3px 6px #ac985559;
}

.navbar__last-link {
  padding-right: 0;
}

.navbar__last-link.active {
  padding-right: 1rem;
}

@media only screen and (width <= 715px) {
  .navbar__nav {
    flex-direction: column;
    width: 100%;
    height: 0;
    transition: all .3s;
    overflow: hidden;
  }

  .navbar__nav.show {
    height: 120px;
    margin-top: 1rem;
    display: flex;
  }

  .navbar__item {
    text-align: center;
    width: 100%;
    margin-left: 0;
  }

  .navbar__link {
    width: 100%;
  }

  .navbar__last-link {
    padding-right: 1rem;
  }
}

@media only screen and (width <= 580px) {
  .navbar__logo {
    width: 150px;
  }
}

.hero {
  z-index: -1;
  background-image: linear-gradient(#0003, #0003), url("home.c2e61ee5.jpg");
  background-size: cover;
  background-attachment: local;
  height: 600px;
}

.hero__content {
  flex-direction: column;
  justify-content: center;
  height: 600px;
  display: flex;
}

.hero__header, .hero__keyword {
  color: #fff;
  text-shadow: 0 3px 6px #0000001f;
}

.hero__keyword {
  font-size: 4rem;
  font-weight: 600;
  display: block;
}

@media only screen and (width <= 600px) {
  h1 {
    font-size: 2.5rem;
  }

  .hero__keyword {
    font-size: 3rem;
  }

  .hero, .hero__content {
    height: 400px;
  }
}

@media only screen and (width <= 450px) {
  h1 {
    font-size: 2rem;
  }

  .hero__keyword {
    font-size: 2.5rem;
  }

  .hero, .hero__content {
    height: 300px;
  }
}

section {
  padding: 3rem 0;
}

@media only screen and (width <= 780px) {
  section {
    padding: 2rem 0;
  }
}

.services {
  background-color: var(--secondary-color);
}

.services__header {
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 3rem;
}

.services__clippath-container {
  width: 0;
  height: 0;
  position: absolute;
  overflow: hidden;
}

.services__image {
  object-fit: cover;
  clip-path: url("#services__clippath");
  width: 150px;
  height: 150px;
  margin: 0 auto;
  display: block;
}

.services__title {
  text-align: center;
  margin-top: .7rem;
  font-size: 1.2rem;
}

.services__description {
  text-align: center;
  padding: 0 20px;
  font-size: 1rem;
}

@media only screen and (width <= 1100px) {
  .services__image {
    width: 100px;
    height: 100px;
  }
}

@media only screen and (width <= 880px) {
  .grid--1x4 {
    grid-template-columns: repeat(2, 1fr);
  }

  .services__image {
    width: 150px;
    height: 150px;
  }

  .services__description {
    text-align: center;
    max-width: 250px;
    font-size: 1rem;
  }
}

@media only screen and (width <= 570px) {
  .grid--1x4 {
    grid-template-columns: repeat(2, 1fr);
    gap: 30px 10px;
  }

  .services__image {
    width: 130px;
    height: 130px;
  }

  .services__header {
    margin-bottom: 2rem;
  }
}

@media only screen and (width <= 450px) {
  .grid--1x4 {
    grid-template-columns: repeat(1, 1fr);
    gap: 30px 10px;
  }

  .services__description {
    max-width: 100%;
    padding: 0 20px;
  }
}

.bio {
  background-image: linear-gradient(var(--primary-color-shade-darker), #fff);
}

.bio-container {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.bio-photo img {
  width: 200px;
}

.bio-desc {
  text-align: center;
  max-width: 500px;
  margin-top: 20px;
}

@media only screen and (width <= 543px) {
  .bio-photo img {
    width: 150px;
    margin-bottom: 10px;
    margin-right: 0;
  }

  .bio-desc {
    max-width: 90%;
    margin-top: 0;
  }
}

.contact__header {
  text-transform: uppercase;
  text-align: center;
  margin-bottom: .5rem;
}

.contact__sub-header {
  text-align: center;
  margin-bottom: 3rem;
  font-weight: 500;
}

.form {
  max-width: 600px;
  margin: 0 auto;
}

.form__control {
  border: 1px solid var(--primary-color);
  box-shadow: var(--box-shadow);
  background-color: var(--primary-color-shade);
  border-radius: 7px;
  outline: none;
  width: 100%;
  margin-bottom: 1.3rem;
  padding: 1.2rem 1rem;
  font-family: Poppins, sans-serif;
  font-size: 1.2rem;
  transition: box-shadow .3s;
  display: block;
}

.form__control::placeholder {
  color: var(--primary-color);
}

.form__control:focus {
  box-shadow: 1px 3px 6px #ac985559;
}

textarea.form__control {
  resize: none;
}

.form__submit-btn {
  background-color: var(--primary-color);
  color: #fff;
  cursor: pointer;
  border: none;
  border-radius: 2px;
  outline: none;
  padding: 1rem 1.3rem;
  font-family: Poppins, sans-serif;
  font-size: 1.1rem;
  transition: background-color .3s;
  box-shadow: 0 3px 6px #ac985559;
}

.form__submit-btn:hover, .form__submit-btn:focus {
  background-color: var(--primary-color-lighter);
}

.footer {
  background-color: var(--primary-color-shade);
}

.footer__content {
  display: flex;
}

.footer section {
  flex: 1 1 0;
}

.footer__header {
  text-transform: uppercase;
  margin-bottom: 1rem;
}

.goal {
  margin-right: 2rem;
}

.goal__text {
  font-size: 1.3rem;
}

.contact-info__group {
  align-items: center;
  margin-bottom: 1rem;
  font-size: 1.3rem;
  font-weight: 500;
  display: flex;
}

.contact-info__phone, .contact-info__email {
  margin-left: 1rem;
}

.contact-info__icon {
  width: 32px;
  height: 32px;
}

.footer__copyright {
  padding-bottom: 3rem;
}

@media only screen and (width <= 860px) {
  .footer__content {
    flex-direction: column;
  }

  .goal {
    padding-bottom: 1rem;
  }

  .contact-info {
    padding-top: 0;
    padding-bottom: .6rem;
  }

  .goal__text, .contact-info__group {
    font-size: 1rem;
  }

  .footer__header {
    margin-bottom: .7rem;
  }

  .footer__copyright {
    padding-bottom: 2rem;
  }
}

.link__hidden {
  display: none;
}
/*# sourceMappingURL=index.7d8a45f5.css.map */
