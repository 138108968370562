.menu-btn {
  display: none;
  justify-content: center;
  align-items: center;
  width: 26px;
  height: 30px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  -webkit-tap-highlight-color: transparent;
}

.menu-btn__burger,
.menu-btn__burger::before,
.menu-btn__burger::after {
  width: 24px;
  height: 3px;
  border-radius: 5px;
  background-color: var(--primary-color);
  transition: all 0.3s ease-in-out;
}

.menu-btn__burger::before,
.menu-btn__burger::after {
  content: "";
  position: absolute;
}

.menu-btn__burger::before {
  transform: translateY(-7px);
}

.menu-btn__burger::after {
  transform: translateY(7px);
}

/* Open */

.menu-btn.open .menu-btn__burger {
  transform: translateY(50px);
  background: transparent;
}

.menu-btn.open .menu-btn__burger::before {
  transform: translateY(-50px);
}

.menu-btn.open .menu-btn__burger::after {
  transform: translateY(-43px);
}

@media screen and (max-width: 715px) {
  .menu-btn {
    display: flex;
  }
}
